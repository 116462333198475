import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import { createI18n } from 'vue-i18n';
import * as Sentry from "@sentry/vue";
import App from './App.vue';
import router from '@/router/routes';
import Toaster from '@meforma/vue-toaster';
import Toast from 'vue3-toast-single'
import CanvasJSChart from '@canvasjs/vue-charts';
import vue3GoogleLogin from 'vue3-google-login'
import VueSweetalert2 from 'vue-sweetalert2';
import VNetworkGraph from "v-network-graph"
import VueGoogleMaps from '@fawmi/vue-google-maps'
import print from 'vue3-print-nb'
import VueDatePicker from '@vuepic/vue-datepicker';
import vSelect from 'vue-select'
import VueApexCharts from "vue3-apexcharts";
import VueTippy from 'vue-tippy';

/*! purgecss start ignore */
import 'vue3-toast-single/dist/toast.css'
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import "v-network-graph/lib/style.css"
import '@vuepic/vue-datepicker/dist/main.css'
import 'tippy.js/dist/tippy.css';
/* csspurge end ignore */

import "bootstrap";

const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

const i18n = createI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages: {
    en: {
      Username: 'User name',
      Password: 'Password',
      AccountType: 'Account Type',
      create: 'Create an Account',
      Signup: 'Sign up',
      Login: 'Login',
      FullName: 'Full Name',
      PhoneNumber: 'Phone Number',
      Email: 'Email',
      Freelancer: 'Freelancer',
      Auditfirms: 'Audit firms',
      Suppliers: 'Suppliers ',
      Regulator: 'Regulator',
      Organization: 'Organization',
      Dashboard: 'Dashboard',
      projects: 'projects',
      Certificate: 'Certificate',
      Compliance: 'Compliance',
      Finance: 'Finance',
      Reports: 'Reports',
      IDcompliance: 'ID compliance',
      Accounttype: 'Account type',
      Compliancename: 'Compliance name',
      DefinedCompliance: 'Defined Compliance',
      Date: 'Date',
      Action: 'Action',
      Ps: 'Post project to supplier',
      Pf: 'Post project to freelancer',
      Pq: 'Post project for Quotation',
      Pendingprojects: 'Pending projects',
      Ip: 'In process projects',
      Completedprojects: 'Completed projects',
      Cancelledprojects: 'Cancelled projects',
      ProjectName: 'Project name',
      af: 'Authorized Audit Firms',
      fp: 'Freelancer can bid project',
      Cancelled: 'Cancelled',
      Inprocess: 'In process',
      Pending: 'Pending',
      All: 'All'
    },
    ar: {
      Username: 'اسم المستخدم',
      Password: 'كلمة السر',
      AccountType: 'حساب',
      create: 'جديد! إنشاء حساب',
      Signup: 'تسجيل',
      Login: 'دخول',
      FullName: 'الإسم الكامل',
      PhoneNumber: 'الجوال',
      Email: 'البريد الالكتروني',
      Freelancer: 'الأشخاص المستقلين',
      Auditfirms: 'شركات التدقيق',
      Suppliers: 'الموردين ',
      Regulator: 'جهة حكومية',
      Organization: 'شركة',
      Dashboard: 'الصفحة الرئيسية',
      projects: 'المشاريع',
      Certificate: 'الشهادات',
      Compliance: 'الضوابط ',
      Finance: 'المالية',
      Reports: 'التقارير',
      IDcompliance: 'رقم الضابط',
      Accounttype: 'نوع الحساب',

      Compliancename: 'اسم الضابط',
      DefinedCompliance: 'الضابط',
      Date: 'التاريخ',
      Action: 'الاجراءات',
      Ps: 'مشروع الى مورد',
      Pf: 'مشروع الى الأشخاص المستقلين',
      Pq: 'عرض سعر لمشروع ',
      Pendingprojects: 'المشاريع المعلقة',
      Ip: 'مشاريع تحت التنفيذ',
      Completedprojects: 'مشاريع منجزة',
      Cancelledprojects: 'مشاريع ملغاة',
      ProjectName: 'اسم المشروع',
      af: 'شركة التدقيق المعتمدة',
      fp: 'يمكن للشخص المستقل التقديم على المشروع',
      Cancelled: 'ملغاة',
      Inprocess: 'تحت التنفيذ',
      Pending: 'معلقة',
      All: 'الكل'
    }
  }
});

const app = createApp(App);

// Init Sentry
Sentry.init({
  app,
  dsn: "https://35cf52ae3b8e7ec952d3548a13561d3a@o4507843216015360.ingest.de.sentry.io/4507843557589072",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  
  // Tracing
  tracesSampleRate: 1.0, 
  
  //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/apis\.glasshub\.ai/,
    /^https:\/\/apis\.dev\.glasshub\.ai/,
    /^https:\/\/apis\.staging\.glasshub\.ai/,
  ],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(VueGoogleMaps, {
  load: {
    // 'YOUR_API_KEY_COMES_HERE'
    // 'AIzaSyDNS7nXnbp8nqbAzU5h1nuqAK5kaWAsP90'
    key: 'AIzaSyAdxmehf5dM8SXOKluCcljMb6QfzoNjv1g',
    libraries: "places"
  },
});

app.use(print);
app.use(router);
app.use(pinia);
app.use(VueApexCharts);
app.use(i18n);
app.component('v-select', vSelect);
app.use(Toaster, { timeout: 3000 });
app.use(VueSweetalert2);
app.use(CanvasJSChart);
app.use(Toast, { verticalPosition: 'top', duration: 3500, horizontalPosition: 'right', transition: 'transition' });
app.use(VNetworkGraph);
app.use(
  VueTippy,
  {
    directive: 'tippy',
    component: 'tippy',
    componentSingleton: 'tippy-singleton',
    defaultProps: {
      placement: 'top',
      allowHTML: true,
    },
  }
);

app.component('VueDatePicker', VueDatePicker);
app.mount('#app');

export default app;
